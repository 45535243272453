<template>
  <section class="directflightsonly_area mobile-area" v-if="!hideCondition">
    <div class="container px-5">
      <div class="directflightsonly_innerbox" id="main-filter-div">
        <div class="flex-column w-100 searchtabtotalbox">
          <nav class="searchtab_menu">
            <b-tabs @input="changeCategory" v-model="tabIndex" nav-class="nav nav-tabs">
              <b-tab
              v-for="(cate, index) in showingCategories"
              :key="index"
              :active="index === tabIndex"
              title-item-class="nav-link"
            >
              <template #title>
                {{ cate.name[lang] }}
              </template>
              <b-card-text v-if="activeCategory && activeCategory.dealTypeCode === 'vacation_pack'">
                <search-panel-fh
                  v-if="tabIndex === index"
                  :category="activeCategory"
                  :agentList="agentList"
                  :type="type"/>
              </b-card-text>
              <b-card-text v-if="activeCategory && activeCategory.dealTypeCode === 'Flight_Only'">
                <search-panel-fh
                  v-if="tabIndex === index"
                  :category="activeCategory"
                  :agentList="agentList"
                  :type="type" />
              </b-card-text>
              <b-card-text v-else-if="activeCategory && activeCategory.dealTypeCode === 'Organize_tour_packages'">
                <search-panel-fh
                  v-if="tabIndex === index"
                  :category="activeCategory"
                  :agentList="agentList"
                  :type="type" />
              </b-card-text>
              </b-tab>
            </b-tabs>
          </nav>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { BTabs, BTab, BCardText } from 'bootstrap-vue';
import TemplateSearchTabs from './TemplateSearchTabs';

export default {
  name: 'sabre-search-pane',
  mixins: [TemplateSearchTabs],
  components: {
    BTabs,
    BTab,
    BCardText,
    searchPanelFh: () => import('../searchPanel/SearchPanelFH'),
  },
  computed: {
    ...mapGetters({
      airlineCompanies: 'GET_SABRE_AIRLINE_COMPANIES',
      countries: 'GET_SABRE_COUNTRIES',
      airports: 'GET_SABRE_AIRPORTS',
    }),
  },
  beforeMount() {
    if (!this.airlineCompanies) this.$store.dispatch('FETCH_SABRE_AIRLINE_COMPANIES');
    if (!this.countries) this.$store.dispatch('FETCH_SABRE_COUNTRIES');
    if (!this.airports) this.$store.dispatch('FETCH_SABRE_AIRPORTS');
  },
};

</script>

<style scoped>
.directflightsonly_area {
  padding: 44px 0;
  width: 100%;
  margin-bottom: 60px;
  position: relative;
  margin-top: -276px;
  z-index: 15;
  background: linear-gradient(90deg, #059AD5 0%, #0261AB 100%);
}
.directflightsonly_area .directflightsonly_innerbox {
  padding: 30px 32px;
  width: 100%;
  border-radius: 24px;
  background-color: #FFFFFF;
  box-shadow: 3px 3px 7px 0 rgba(3,67,117,0.26);
}
@media (min-width: 1700px) {
  .container.px-5 {
    max-width: 1920px;
    margin: auto;
  }
}
@media (min-width: 1440px) {
  .container.px-5 {
    padding-left: 2rem!important;
    padding-right: 2rem!important;
  }
}
</style>

<style>
.directflightsonly_area .btn-group.opacity-button {
  opacity: 0.3;
}
.searchtab_menu .nav-tabs {
  border-bottom: 1px solid #D3D3D3;
}
.searchtab_menu .nav-tabs .nav-item.show .nav-link,
.searchtab_menu .nav-tabs a.nav-link.active {
  color: #0460AA;
  border-bottom: 2px solid #0460b2;
  margin-bottom: -1px;
  opacity: 1;
  font-weight: bold;
}

.searchtab_menu .nav-tabs li.nav-link {
  padding-bottom: 0;
}
.searchtab_menu .nav-tabs li.nav-link:hover {
  border-color: transparent;
}
.searchtab_menu .nav-tabs a.nav-link {
  background: none;
  border: none;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  font-size: 20px;
  line-height: 29px;
  color: #353535;
  opacity: 0.5;
  outline: none;
  color: inherit;
}
.searchtabtotalbox .tab-content {
  padding-top: 24px;
  border: none;
}

@media (max-width: 1199.98px){
  .directflightsonly_area.mobile-area {
    display: block;
    padding: 15px 0;
    margin-top: 0;
    margin-bottom: 0;
    z-index: 1500;
  }
  .directflightsonly_area.mobile-area .directflightsonly_innerbox {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
  }
  .mobile-area .searchtab_menu .nav-tabs {
    border-bottom: none;
    border-radius: 23px;
    background-color: #FFFFFF;
    box-shadow: 3px 3px 7px 0 rgba(3,67,117,0.26);
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 5px;
  }
  .mobile-area .searchtab_menu .nav-tabs .nav-link.active {
    border-radius: 21px;
    background-color: #0261AB;
    color: #fff;
  }
  .mobile-area .searchtab_menu .nav-tabs li.nav-link {
    padding: 0;
  }
  .mobile-area .searchtab_menu .nav-tabs a.nav-link {
    font-size: 16px;
    line-height: 24px;
    margin-left: 0;
    white-space: nowrap;
    padding: 5px 18px;
  }
  .mobile-area .searchtabtotalbox .tab-content {
    padding-top: 0;
    margin-top: 18px;
    border-radius: 16px;
    background-color: #FFFFFF;
    box-shadow: 3px 3px 7px 0 rgba(3,67,117,0.26);
    padding: 23px;
  }
  .mobile-area .directflightsonly_top.directbottom .btn-group {
    margin-left: 30px;
  }
  .mobile-area .directflightsonly_top.directbottom .btn-group:after {
    display: none;
  }
  .mobile-area .heading_droupdown {
    margin-bottom: 20px;
  }
  .mobile-area .dropdown-menu.fromwheredropdown {
    height: 100vh;
    max-width: 100%;
    min-width: 100%;
    border-radius: 0;
    background-color: #FFFFFF;
    box-shadow: none;
    border: none;
    box-sizing: border-box;
    padding: 24px;
    left: 0px !important;
    z-index: 10;
    overflow: auto;
  }
  .fixedbox {
    left: 0 !important;
    height: 100vh;
    width: 100%;
    position: fixed !important;
    top: 0 !important;
    transform: inherit !important;
    border-radius: 0;
  }
  .mobile-area .cleanup_close {
    position: absolute;
    left: 46px;
    top: 15px;
    z-index: 9;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 17px;
    color: #aaa;
  }
  .mobile-area .month-wrapper .row{
    overflow: auto;
    height: 80vh;
  }
  .mobile-area .nav.nav-tabs.nav.nav-tabs{
    overflow: auto;
    width: 100%;
  }
  .mobile-area .compositionarea_footer .btn-group-area {
    margin-top: 0;
    padding: 20px 20px 0px 20px;
  }
}
</style>
