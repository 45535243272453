import { mapGetters } from 'vuex';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  name: 'TemplateSearchTabs',
  mixins: [imageUrlMixin],
  props: {
    msg: String,
    type: String,
    closeSearchModal: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      images: [],
      tabIndex: 0,
      query: this.$route.query,
      activeCategory: null,
    };
  },
  computed: {
    ...mapGetters({
      categories: 'GET_CATEGORIES',
      category: 'GET_CURRENT_CATEGORY',
      // isLoading: 'GET_DATE_LOADING_STATE',
      holidayList: 'GET_HOLIDAY_DATA',
      lang: 'GET_LANGUAGE',
      hotelOnly: 'GET_HOTEL_ONLY',
      device: 'GET_DEVICE',
      isLanding: 'GET_IS_LANDING_PAGE',
      isAgencyLogin: 'IS_AGENCY_AUTHORIZED',
      allAgentList: 'GET_ALL_AGENT_LIST',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      bypassPaymentState: 'GET_BYPASS_PAYMENT_STATE',
      limitDealsState: 'GET_LIMIT_DEALS_STATE',
      enableSearchAgentState: 'GET_ENABLE_SEARCH_AGENT_STATE',
      landingShowCategory: 'GET_SHOW_SEARCH_TAB_CATEGORY_FOR_LANDING',
    }),
    agentList() {
      const list = this.allAgentList;
      if (list.length === 0) return [];
      return list.map((elm, inx) => ({ id: inx, value: elm.agt_Code, label: `${elm.agt_Code} - ${elm.agt_Name}` }));
    },
    isSearchPage() {
      return this.$route.name === 'search-result';
    },
    showingCategories() {
      const { categories } = this;
      if (!categories) return [];
      // const groupCategories = categories.filter((cate) => cate.active && cate.destinations.length).reduce((group, cate) => {
      const groupCategories = categories.filter((cate) => cate.active && cate.code !== 'FO_OW').reduce((group, cate) => {
        const addedCategory = group.find((el) => (el.dealTypeCode === cate.dealTypeCode || (el.code === 'vacation_pack' && cate.code === 'sport_pack')));
        if (addedCategory) {
          addedCategory.series.push(cate);
        } else {
          const currGroup = { ...cate, series: [cate] };
          group.push(currGroup);
        }
        return group;
      }, []);
      return groupCategories;
    },
    hideCondition() {
    /**
     * When login an agent or marketer, bypassPaymentState is true only.
     * When limitDealsState is true, always.
     * When the query has a secret param.
     * When enableSearchAgentState is true, must be false.
     */
      const { isAgencyLogin, bypassPaymentState, limitDealsState, enableSearchAgentState, query } = this;
      return (((!isAgencyLogin && bypassPaymentState) || (!enableSearchAgentState && limitDealsState)) && query.secret !== process.env.VUE_APP_TEMPORARY_SECRET_PARM);
    },
    showOrgSearchTab() {
      const { destination, areaType } = this.query;
      return destination === 'EXTRA100ORG' && areaType === 'true';
    },
  },
  watch: {
    categories() {
      if (this.isLanding && (this.query.dealType === 'Organize_tour_packages' || this.query.dealType === 'Flight_Only' || this.landingShowCategory)) {
        setTimeout(() => { this.setTabIndex(); }, 1000);
      } else {
        this.setTabIndex();
      }
    },
    landingShowCategory() {
      this.setTabIndex();
    },
    // category() {
    //   this.activeCategory = this.category;
    // },
  },
  async created() {
    if (this.type === 'tabs' || this.type === 'side') {
      if (!this.holidayList) this.$store.dispatch('FETCH_HOLIDAY_DATA', new Date().getFullYear());
      if (this.isOdysseySite && this.isAgencyLogin) this.$store.dispatch('FETCH_ALL_AGENT_LIST');
      this.setTabIndex();
    } else if (this.type === 'price') {
      this.$store.dispatch('FETCH_AVAILABLE_DESTINATION_FOR_MARKETER');
    }
  },
  mounted() {
    this.activeCategory = this.category;
    this.images = [
      `${this.imageDomain}/assets/img/search_panel_vacation_packages_tab_icon.png`,
      `${this.imageDomain}/assets/img/search_panel_orgenized_tab_tour_icon.png`,
      `${this.imageDomain}/assets/img/search_panel_flight_tab_icon.png`,
      `${this.imageDomain}/assets/img/icon-sport-pack.png`,
      `${this.imageDomain}/assets/img/icon-tour-pack.png`,
      `${this.imageDomain}/assets/img/icon-domestic-vacation.png`,
      `${this.imageDomain}/assets/img/icon-flight-pack.png`,
      `${this.imageDomain}/assets/img/icons-hotel-only.png`,
    ];
    this.tabIndex = this.categories.findIndex((cat) => cat.code === this.category.code);
  },
  methods: {
    setTabIndex() {
      this.tabIndex = this.showingCategories?.findIndex(
        (item) => item.dealTypeCode === this.query.categoryId || (this.isLanding && item.dealTypeCode === this.query.dealType),
      );
      if (this.landingShowCategory) {
        this.tabIndex = this.showingCategories?.findIndex(
          (item) => item.dealTypeCode === this.landingShowCategory,
        );
      }

      if (this.tabIndex === -1) {
        const currCate = this.categories?.find(
          (item) => item.code === this.query.categoryId || (this.isLanding && item.dealTypeCode === this.query.dealType),
        );
        if (currCate) {
          this.tabIndex = this.showingCategories?.findIndex(
            (item) => item.dealTypeCode === currCate.dealTypeCode || (this.isLanding && item.dealTypeCode === this.dealTypeCode),
          );
        }
      }
      this.activeCategory = this.showingCategories[this.tabIndex];
      // if (this.query.categoryId === 'Hotel_Only') this.tabIndex = 3; // Hotel Only
    },

    changeCategory(inx) {
      if (!this.showingCategories || inx === -1) return;
      // const categoryId = inx === this.showingCategories.length ? 'Hotel_Only' : this.showingCategories[inx].dealTypeCode;
      // const categoryId = this.showingCategories[inx].dealTypeCode;
      // this.$store.dispatch('UPDATE_CURRENT_CATEGORY', { categoryId });
      this.activeCategory = this.showingCategories[inx];
      this.$store.dispatch('UPDATE_CURRENT_CATEGORY', { categoryId: this.activeCategory.code });

      this.$store.dispatch('RESET_SEARCH_CONTENT');
    },
    closeSearchMobile() {
      this.$emit('closeSearchMobile');
    },
  },
};
